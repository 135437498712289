var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.group && _vm.field && _vm.fieldData && _vm.fieldContent
    ? _c(
        "div",
        {
          class: [
            "form-input",
            {
              [`form-input--${_vm.fieldData.type}`]: _vm.fieldData.type,
            },
          ],
          attrs: {
            id: `form-input-${_vm.group}-${_vm.field}`,
            "data-field": _vm.field,
            "data-group": _vm.group,
          },
          on: { reset: _vm.reset, update: _vm.update },
        },
        [
          _vm.fieldData.type === "radio" ||
          _vm.fieldData.type === "radio-multiple"
            ? _c("zg-radio", {
                attrs: {
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  error: _vm.error,
                  "full-width":
                    (_vm.isSingleStep && !_vm.projectValentin) ||
                    _vm.props.fullWidth,
                  horizontal:
                    (_vm.isSingleStep && !_vm.projectValentin) ||
                    _vm.props.horizontal,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  multiple: _vm.fieldData.type === "radio-multiple",
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  options: _vm.options,
                  "read-only": _vm.readOnly,
                  "show-required": _vm.props.required,
                  size: _vm.radioSize,
                  tooltip: _vm.content.tooltip,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                  version: _vm.fieldData.version,
                  vertical: _vm.props.vertical,
                },
                on: {
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : _vm.fieldData.type === "checkbox"
            ? _c(
                "zg-checkbox",
                {
                  attrs: {
                    disabled: _vm.fieldData.disabled,
                    error: _vm.error,
                    "full-width": _vm.isSingleStep || _vm.props.fullWidth,
                    invalid: _vm.invalid,
                    name: `${_vm.group}-${_vm.field}`,
                    "no-margin": _vm.props.noMargin,
                    overlay: _vm.projectValentin,
                    "read-only": _vm.readOnly,
                    "show-required": _vm.props.required,
                    "smaller-label": _vm.props.smallerLabel,
                    tooltip: _vm.content.tooltip,
                    valid: _vm.valid,
                    value: _vm.fieldData.value ? "on" : "off",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.inputTriggered($event)
                    },
                  },
                  model: {
                    value: _vm.modelValue,
                    callback: function ($$v) {
                      _vm.modelValue = $$v
                    },
                    expression: "modelValue",
                  },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.content.label) },
                  }),
                ]
              )
            : _vm.fieldData.type === "date"
            ? _c("zg-date-select", {
                attrs: {
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  "end-year": _vm.fieldData.options.endYear,
                  error: _vm.error,
                  "full-width":
                    (_vm.isSingleStep && !_vm.projectValentin) ||
                    _vm.props.fullWidth,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  language: _vm.language,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  "read-only": _vm.readOnly,
                  "show-required": _vm.props.required,
                  size: _vm.size,
                  "start-year": _vm.fieldData.options.startYear,
                  tooltip: _vm.content.tooltip,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : _vm.fieldData.type === "select" && !_vm.projectValentin
            ? _c("zg-select", {
                attrs: {
                  attrs: _vm.fieldData.attrs,
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  error: _vm.error,
                  "full-width": _vm.isSingleStep || _vm.props.fullWidth,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  options: _vm.options,
                  overlay: _vm.projectValentin,
                  "read-only": _vm.readOnly,
                  "show-required": _vm.props.required,
                  tooltip: _vm.content.tooltip,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : _vm.fieldData.type === "select" && _vm.projectValentin
            ? _c("zg-select-pv", {
                attrs: {
                  attrs: _vm.fieldData.attrs,
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  error: _vm.error,
                  "full-width": _vm.isSingleStep || _vm.props.fullWidth,
                  "helper-text": _vm.helperText,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  options: _vm.options,
                  placeholder: null,
                  "read-only": _vm.readOnly,
                  "show-required": false,
                  size: _vm.size,
                  tooltip: _vm.projectValentinTooltip,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : _vm.fieldData.type === "textarea"
            ? _c("zg-textarea", {
                attrs: {
                  attrs: _vm.fieldData.attrs,
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  error: _vm.error,
                  "full-width": _vm.isSingleStep || _vm.props.fullWidth,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  "read-only": _vm.readOnly,
                  rows: _vm.props.rows,
                  "show-required": _vm.props.required,
                  tooltip: _vm.content.tooltip,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : !_vm.projectValentin
            ? _c("zg-input", {
                attrs: {
                  attrs: _vm.fieldData.attrs,
                  autocomplete: _vm.autocomplete,
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  "email-warning-language": _vm.MARKET,
                  error: _vm.error,
                  "full-width": _vm.isSingleStep || _vm.props.fullWidth,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  lookup: _vm.lookup,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  overlay: _vm.projectValentin,
                  "read-only": _vm.readOnly,
                  "show-required": _vm.props.required,
                  tooltip: _vm.content.tooltip,
                  type: _vm.fieldData.type,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                  toggleLookup: function ($event) {
                    return _vm.resetLookup(_vm.group)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              })
            : _c("zg-input-pv", {
                attrs: {
                  attrs: _vm.fieldData.attrs,
                  autocomplete: _vm.autocomplete,
                  disabled: _vm.fieldData.disabled || _vm.lockedField,
                  "email-warning-language": _vm.MARKET,
                  error: _vm.error,
                  "full-width": _vm.props.fullWidth,
                  "helper-text": _vm.helperText,
                  invalid: _vm.invalid,
                  label: _vm.content.label,
                  lookup: _vm.lookup,
                  name: `${_vm.group}-${_vm.field}`,
                  "no-margin": _vm.props.noMargin,
                  "read-only": _vm.readOnly,
                  "show-required": false,
                  size: _vm.size,
                  tooltip: _vm.projectValentinTooltip,
                  type: _vm.fieldData.type,
                  unit: _vm.unit,
                  valid: _vm.valid,
                  value: _vm.fieldData.value,
                },
                on: {
                  blur: _vm.blurTriggered,
                  focus: _vm.focusTriggered,
                  input: function ($event) {
                    return _vm.inputTriggered($event)
                  },
                  toggleLookup: function ($event) {
                    return _vm.resetLookup(_vm.group)
                  },
                },
                model: {
                  value: _vm.modelValue,
                  callback: function ($$v) {
                    _vm.modelValue = $$v
                  },
                  expression: "modelValue",
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }